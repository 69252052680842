/* installed tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

/* default style settings */
body {
  font-family: "Montserrat", sans-serif;
  max-width: 1535px;
  margin: 0 auto;
  overflow-x: hidden !important;
}

.nav_bg {
  background-size: cover;
  background-position: center;
  background-image: url("./Assets/images/customize-shopper-bd-nav.webp");
}

.hero_bg_right {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  background-image: url("./Assets/images/customize-shopper-bd-color-slider.webp");
}

/* custom slider class */

.slider {
  width: 100%;
  height: 100%;
}

.slide,
.slide img {
  width: 450px;
  height: 400px;
}

.slide {
  transform: scale(0.8);
  transition: 0.5s;
  color: white;
}

.slide-active {
  transform: scale(1);
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.arrow {
  font-size: 100px;
}

.slider_bg_right {
  background-size: cover;
  background-position: center;
  background-image: url("./Assets/images/customize-shopper-bd-color-slider.webp");
}

/* Payment getaway */
.payment_getaway_bg {
  background-size: cover;
  background-position: center;
  background-image: url("./Assets/images/customize-shopper-bd-payment-color.png");
}

/* about */
.about_bg {
  background-size: cover;
  background-position: center;
  background-image: url("./Assets/images/customize-shopper-bd-brush.png");
}

/* Loader */
.pl {
  width: 6em;
  height: 6em;
}

.pl__ring {
  animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: #f42f25;
}

.pl__ring--b {
  animation-name: ringB;
  stroke: #f49725;
}

.pl__ring--c {
  animation-name: ringC;
  stroke: #255ff4;
}

.pl__ring--d {
  animation-name: ringD;
  stroke: #f42582;
}

/* Animations */
@keyframes ringA {
  from,
  4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%,
  54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%,
  to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@keyframes ringB {
  from,
  12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%,
  62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%,
  to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%,
  58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {
  from,
  8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%,
  50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

/*  */
/* box shadow */
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* product background */
.color_bg_left {
  background-size: cover;
  background-position: center;
  background-clip: unset;
  background-image: url("./Assets/images/customize-shopper-bd-color-slider.webp");
}

.customize_bg {
  background-size: cover;
  background-position: center;
  background-image: url("./Assets/images/customize-shopper-bd-payment-color.png");
}

.order_bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  background-image: url("./Assets/images/customize-shopper-bd-order-bg.png");
}

/* client/src/App.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 400px;
  height: 500px;
  background-color: white;
  border: none;
  border-radius: 10px;
}
